import React from 'react'
import tw from 'twin.macro'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export default function TextImageSquares(props) {
  const { text, imageLocation } = props.content
  const backgroundImage = getImage(
    props.content.image[0]?.localFile?.childImageSharp?.gatsbyImageData
  )

  return (
    <section
      className="textImageSquares mm-mx-dynamic"
      css={[tw`md:flex`, imageLocation && tw`flex-row-reverse`]}
    >
      <div className="mm-h-half" css={tw`md:w-1/2 relative overflow-hidden`}>
        {backgroundImage && (
          <GatsbyImage
            image={backgroundImage}
            alt={props.content.image[0]?.title}
            css={tw`absolute w-full h-full`}
          />
        )}
      </div>
      <div
        css={tw`flex flex-wrap content-center items-center md:w-1/2 md:px-10 py-10`}
      >
        <div css={tw`mx-auto xl:max-w-md`}>
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
        </div>
      </div>
    </section>
  )
}
